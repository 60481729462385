import {
  CSSObject,
  Drawer,
  styled,
  Toolbar,
  Theme,
  DrawerProps,
} from '@formbio/ui/components';
import CSS from 'csstype';
import { ReactNode } from 'react';
import {
  NAV_DRAWER_WIDTH,
  NAV_DRAWER_MINIED,
} from '@formbio/ui/theme/constants';

const openedMixin = (theme: Theme): CSSObject => ({
  width: NAV_DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: NAV_DRAWER_MINIED,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
});

const StyledDrawer = styled(Drawer, {
  shouldForwardProp(propName) {
    return (
      propName !== 'width' &&
      propName !== 'isMinied' &&
      propName !== 'getBackgroundProps'
    );
  },
})<{
  width: number;
  isMinied?: boolean;
  getBackgroundProps?: (theme: Theme) => BackgroundProps;
}>(({ theme, width, isMinied, getBackgroundProps }) => ({
  width: width || NAV_DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '.MuiDrawer-paper': {
    ...getBackgroundProps?.(theme),
  },
  ...(!isMinied && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(isMinied && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  '.logo': {
    padding: isMinied
      ? theme.spacing(1, 0, 0.5, 0.5)
      : theme.spacing(1, 0, 0.5, 1.5),
  },
  '.content': {
    flex: 1,
    paddingTop: theme.spacing(2),
  },
  '.actions': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    // @ts-expect-error - just for halloween
    // TODO remove this after
    ...(getBackgroundProps?.(theme).textColorAction && {
      '.icon': {
        color: 'white',
      },
      '.text': {
        color: 'white',
      },
    }),
  },
}));

export interface BackgroundProps {
  backgroundColor?: CSS.Property.BackgroundColor;
  backgroundImage?: CSS.Property.BackgroundImage;
  backgroundRepeat?: CSS.Property.BackgroundRepeat;
  backgroundPositionY?: CSS.Property.BackgroundPositionY;
  backgroundSize?: CSS.Property.BackgroundSize;
}

export interface NavigationDrawerProps {
  children: React.ReactNode;
  variant?: DrawerProps['variant'];
  isMinied?: boolean;
  logo?: ReactNode;
  isBelowToolbar?: boolean;
  actions?: React.ReactNode;
  getBackgroundProps?: (theme: Theme) => BackgroundProps;
  anchor?: DrawerProps['anchor'];
}

export default function NavigationDrawer({
  children,
  variant = 'permanent',
  isMinied = false,
  logo,
  isBelowToolbar,
  actions,
  getBackgroundProps,
  anchor = 'left',
}: NavigationDrawerProps) {
  return (
    <StyledDrawer
      variant={variant}
      open
      width={240}
      isMinied={isMinied}
      getBackgroundProps={getBackgroundProps}
      anchor={anchor}
    >
      {isBelowToolbar && <Toolbar />}
      {logo && <div className='logo'>{logo}</div>}
      <div className='content'>{children}</div>
      <div className='actions'>{actions}</div>
    </StyledDrawer>
  );
}
