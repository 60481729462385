import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  createVectorEntityVADR,
  getVectorEntityVADR,
  getVectorEntityVadrColumnValues,
  updateVectorEntityVADR,
} from '../../services/vadr';
import {
  CreateVectorVADRParams,
  DATACORE_VADR_TYPE,
  DATACORE_VADR_VALUE_TYPE,
  DATACORE_VECTOR_TYPE,
  DatacoreResponse,
  UpdateVectorVADRParams,
  ConfigColumnValue,
  VadrColumnValuesResponse,
} from '../../types';
import useInvalidation from '../useInvalidation';
import sortBy from 'lodash/sortBy';

export function useVectorVADRs({
  orgId,
  pid,
  vectorId,
  options,
}: {
  orgId: string;
  pid: string;
  vectorId: string;
  options?: Omit<UseQueryOptions<DatacoreResponse>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<DatacoreResponse>({
    queryKey: [
      DATACORE_VECTOR_TYPE,
      DATACORE_VADR_VALUE_TYPE,
      orgId,
      pid,
      vectorId,
    ],
    queryFn: () => getVectorEntityVADR(orgId, pid, vectorId),
    enabled: !!orgId && !!pid,
    ...options,
  });
}

export function useVectorVadrColumnValues({
  orgId,
  pid,
  indicationId = '',
  vectorId,
  options,
}: {
  orgId: string;
  pid: string;
  indicationId?: string;
  vectorId?: string;
  options?: Omit<
    UseQueryOptions<VadrColumnValuesResponse>,
    'queryKey' | 'queryFn'
  >;
}) {
  return useQuery<VadrColumnValuesResponse>({
    queryKey: [
      DATACORE_VECTOR_TYPE,
      DATACORE_VADR_TYPE,
      orgId,
      pid,
      indicationId || vectorId,
    ],
    queryFn: () =>
      getVectorEntityVadrColumnValues({
        oid: orgId,
        pid,
        indicationId,
        vectorId,
      }),
    enabled: !!orgId && !!pid,
    select: (data) => {
      const sortingFunction = (item: ConfigColumnValue) =>
        item.displayName.toLowerCase();
      return {
        assays: sortBy(data.assays, sortingFunction),
        attributes: sortBy(data.attributes, sortingFunction),
        attributeCategories: sortBy(data.attributeCategories, sortingFunction),
      };
    },
    ...options,
  });
}

export function useCreateVectorVADR() {
  return useMutation<
    AxiosResponse<{ id: string }>,
    AxiosError<{ message: string }>,
    CreateVectorVADRParams
  >({
    mutationFn: (data: CreateVectorVADRParams) => {
      return createVectorEntityVADR(data.orgId, data.pid, data.datacoreId);
    },
    // no onSuccess + invalidate here because we don't
    // want to do it on a per VADR basis but
    // at the end.
  });
}

export function useUpdateVectorVADR() {
  return useMutation<
    AxiosResponse<{ id: string }>,
    AxiosError<{ message: string }>,
    UpdateVectorVADRParams
  >({
    mutationFn: (data: UpdateVectorVADRParams) => {
      return updateVectorEntityVADR(
        data.orgId,
        data.pid,
        data.vadrEntityId,
        data.vadrData,
        // TOOD: Update when we actually update `typeSchemaVersionName`
        'v1.0.0',
      );
    },
    // no onSuccess + invalidate here because we don't
    // want to do it on a per VADR basis but
    // at the end.
  });
}

export function useInvalidateVADR() {
  return useInvalidation(DATACORE_VECTOR_TYPE);
}
